import React, { useState, useMemo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import styles from './UploadDialog.module.scss';
import resumeApi from '../../../../api/resume.api';
import userAuthStore from '../../../../stores/auth.store';
import formStore from '../../../../stores/form.store';
import DialogContentText from '@mui/material/DialogContentText';
import useToast from 'src/components/Toast/useToast';
import UploadResume from './UploadResume';
import JobSelector from './JobSelector';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { useRequest } from 'ahooks';
import jobApi from 'src/api/job.api';

const shortid = require('shortid');

function ConfirmDialog({
	confirmDialogOpen,
	setConfirmDialogOpen,
	handleSkip,
}) {
	const handleClose = () => {
		setConfirmDialogOpen(false);
	};

	return (
		<div>
			<Dialog
				fullWidth={true}
				maxWidth="md"
				// sx={{ '& .MuiDialog-paper': { padding: '20px 20px' } }}
				open={confirmDialogOpen}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						"Please note that once you click confirm, you will no longer have
						access to the resume upload function unless you upgrade to a premium
						user. As a basic user, you will need to create your resume manually,
						without the benefit of the auto-parsing feature."
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button sx={{ textTransform: 'none' }} onClick={handleClose}>
						Disagree
					</Button>
					<Button
						sx={{ textTransform: 'none' }}
						onClick={() => {
							setConfirmDialogOpen(false);
							handleSkip();
						}}
						autoFocus
					>
						Agree
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default function UploadDialog({
	openUploadDialog,
	setOpenUploadDialog,
	resumeUploaded,
	setResumeUploaded,
	showBackdrop,
	closeBackdrop,
	getPrimaryResume = () => {},
}) {
	const userId = userAuthStore((state) => state.userId);
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const [selectedfile, SetSelectedFile] = useState([]);
	const { showSuccess, showError } = useToast();

	const handleSubmit = async () => {
		if (selectedfile[0].file) {
			try {
				setOpenUploadDialog(false);
				showBackdrop();
				const response = await resumeApi.uploadResume({
					jobId: '',
					resumeFile: selectedfile[0].file,
					isPrimary: true,
				});
				formStore.setState({
					selectedResumeId: response.data.id,
					resumeMode: 'detail',
				});
				setResumeUploaded(1);
				showSuccess('Your resume has been uploaded successfully');
				console.log('getting primary resume....');
			} catch (err) {
				console.error(err);
				showError(err?.errorMsg);
			} finally {
				setTimeout(() => {
					closeBackdrop();
					getPrimaryResume();
				}, 1000);
			}
		}
	};

	const handleSkip = async () => {
		try {
			setOpenUploadDialog(false);
			await resumeApi.uploadResume({ userId, skip: true });
			setResumeUploaded(1);
		} catch (err) {
			console.error(err);
		}
	};

	const filesizes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	};

	const InputChange = (e) => {
		for (let i = 0; i < e.target.files.length; i++) {
			let reader = new FileReader();
			let file = e.target.files[i];
			reader.onloadend = () => {
				SetSelectedFile((preValue) => {
					return [
						// ...preValue,
						{
							id: shortid.generate(),
							file: file,
							filename: e.target.files[i].name,
							filetype: e.target.files[i].type,
							fileimage: reader.result,
							datetime:
								e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
							filesize: filesizes(e.target.files[i].size),
						},
					];
				});
			};
			if (file) {
				reader.readAsDataURL(file);
			}
		}
	};

	const DeleteSelectFile = (id) => {
		const result = selectedfile.filter((data) => data.id !== id);
		SetSelectedFile(result);
	};

	const { data: jobListData, loading: loadingJobListData } = useRequest(() =>
		jobApi.listJob({
			sortby: 'updatedAt',
			order: 'desc',
			contain: '',
		})
	);
	// console.log(`jobListData`)
	// console.log(jobListData)

	const jobList = useMemo(() => jobListData?.data?.rows || [], [jobListData]);
	console.log(jobList);

	const [currentStep, setCurrentStep] = useState(0);
	const [selectedJobId, setSelectedJobId] = useState(null);
	const steps = [
		// {
		// 	title: 'Select Job',
		// 	content: (
		// 		<JobSelector
		// 			jobList={jobList}
		// 			selectedJobId={selectedJobId}
		// 			setSelectedJobId={setSelectedJobId}
		// 		/>
		// 	),
		// 	action: (
		// 		<div className="flex flex-row w-full justify-between">
		// 			<Button
		// 				sx={{ textTransform: 'none' }}
		// 				variant="text"
		// 				onClick={() => {
		// 					setOpenUploadDialog(false);
		// 				}}
		// 			>
		// 				Cancel
		// 			</Button>
		// 			<Button
		// 				sx={{ textTransform: 'none' }}
		// 				variant="contained"
		// 				onClick={() => setCurrentStep(1)}
		// 			>
		// 				Next
		// 			</Button>
		// 		</div>
		// 	),
		// },
		{
			title: 'Upload Resume',
			content: (
				<UploadResume
					selectedfile={selectedfile}
					DeleteSelectFile={DeleteSelectFile}
					InputChange={InputChange}
				/>
			),
			action: (
				<div className="flex flex-row w-full">
					{/* <Button
          sx={{ textTransform: 'none' }}
          variant="outlined"
          onClick={() => {
            setOpenUploadDialog(false);
            setResumeUploaded(2);
          }}
        >
          Upload later
        </Button> */}
					{currentStep !== 0 ? (
						<Button
							sx={{ textTransform: 'none' }}
							variant="outlined"
							onClick={() => setCurrentStep(0)}
						>
							Previous
						</Button>
					) : null}
					<div className="grow"></div>
					<Button
						sx={{ textTransform: 'none' }}
						variant="text"
						onClick={() => {
							setOpenUploadDialog(false);
						}}
					>
						Cancel
					</Button>
					<Button
						sx={{ textTransform: 'none' }}
						variant="contained"
						onClick={handleSubmit}
						disabled={selectedfile.length === 0}
					>
						Submit
					</Button>
				</div>
			),
		},
	];

	return (
		<div>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				sx={{ '& .MuiDialog-paper': { minHeight: 400, padding: '20px 20px' } }}
				open={openUploadDialog}
				disableEscapeKeyDown={true}
			>
				<DialogContent>
					<Stepper nonLinear activeStep={currentStep}>
						{steps.map((item, index) => (
							<Step key={item.title} completed={currentStep > index}>
								<StepButton
									color="inherit"
									onClick={() => setCurrentStep(index)}
								>
									{item.title}
								</StepButton>
							</Step>
						))}
					</Stepper>
					{/* <div className="h-80 debug-borderblue flex flex-col">
						<div className="text-base font-semibold text-slate-500">
							Upload and parse your resume file
						</div>
						<div className="basis-10"></div>
						<div className="grow flex flex-row justify-center items-center">
							<div className="relative">
								<IconButton>
									<CloudUploadOutlinedIcon style={{ fontSize: 160 }} />
								</IconButton>
								<input
									type="file"
									className={styles.file}
									onChange={InputChange}
								/>
							</div>
						</div>
						<div className="basis-16">
							{selectedfile.map((data, index) => {
								const { id, filename, datetime } = data;
								return (
									<div className="file-atc-box" key={id}>
										<div className="flex flex-col items-start gap-y-1">
											<span className="text-xs text-slate-700 font-bold pl-0.5">
												{filename}
											</span>
											<span className="text-xs text-slate-700 pl-0.5">
												Modified Time : {datetime}
											</span>
											<button
												type="button"
												className="text-xs text-slate-500 border-b border-slate-500 hover:text-slate-900 hover:border-slate-900 px-1 pb-0.5"
												onClick={() => DeleteSelectFile(id)}
											>
												Delete
											</button>
										</div>
									</div>
								);
							})}
						</div>
					</div> */}
					<div className="pt-4">{steps[currentStep].content}</div>
				</DialogContent>
				<DialogActions>
					{steps[currentStep].action}

					{/* <Button sx={{ textTransform: 'none' }} variant="outlined"
            onClick={() => { setConfirmDialogOpen(true) }}
          >
            I want to create on my own
          </Button> */}
				</DialogActions>
			</Dialog>
			<ConfirmDialog
				handleSkip={handleSkip}
				confirmDialogOpen={confirmDialogOpen}
				setConfirmDialogOpen={setConfirmDialogOpen}
			/>
		</div>
	);
}
