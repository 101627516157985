import {
	Typography,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Stack,
	Button,
	CircularProgress,
	Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeywordDetail from './KeywordDetail';
import { useRequest, useUpdateEffect } from 'ahooks';
import resumeApi from 'src/api/resume.api';
import _ from 'lodash';
import { useMemo } from 'react';

function Keyword({ resumeId, jobData = {} }) {
	const { loading, data: similarityData } = useRequest(
		() => resumeApi.getResumeSimilarity(resumeId),
		{
			ready: !!resumeId,
		}
	);

	// const resumeData = useMemo(() => data?.data || {}, [data]);
	const skills = useMemo(() => similarityData?.data || [], [similarityData]);
	const matchedSkills = useMemo(
		() => _.filter(similarityData?.data, { checked: true }) || [],
		[similarityData]
	);
	// useUpdateEffect(() => {
	//   if (isResumeLoaded) {
	//     updateSimilarity();
	//   }
	// }, [isResumeLoaded]);

	if (loading) {
		return (
			<Box
				display="inline-flex"
				sx={{
					height: '100%',
					width: '100%',
					marginTop: '10px',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Accordion
			elevation={0}
			sx={{
				marginTop: '10px',
				'&::before': {
					backgroundColor: 'transparent',
				},
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				sx={{
					padding: '5px',
				}}
			>
				<Grid container sx={{ gap: '10px' }}>
					<Grid item xs="auto">
						<Stack
							direction="row"
							sx={{
								justifyContent: 'center',
								alignItems: 'center',
								height: '100%',
							}}
						>
							<Typography variant="h4">
								<sup>{matchedSkills.length}</sup>
							</Typography>
							<Typography variant="h4">&frasl;</Typography>
							<Typography variant="h4">
								<sub>{skills.length}</sub>
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={true}>
						<Typography>
							Your resume has {matchedSkills.length} out of {skills.length}{' '}
							keywords that appear in{' '}
							<Box component="span" fontWeight="bold">
								{jobData?.title}{' '}
							</Box>
							at{' '}
							<Box component="span" fontWeight="bold">
								{jobData?.company}
							</Box>
						</Typography>
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					padding: '10px',
				}}
			>
				<KeywordDetail skills={skills} />
			</AccordionDetails>
		</Accordion>
	);
}

export default Keyword;
