import { useRoutes } from 'react-router-dom';
import DefaultLayout from 'src/layouts/DefaultLayout';
import PublicLayout from 'src/layouts/PublicLayout';
import Dashboard from 'src/pages/Dashboard';
import JobFitInsight from 'src/pages/JobFitInsight';
import JobTracker from 'src/pages/JobTracker';
import Account from 'src/pages/Account';
// import ResumeBuilder from 'src/pages/ResumeBuilder';
import Coupon from 'src/pages/Coupon';
import PaymentConfirm from 'src/pages/PaymentConfirm';
import SubscribeConfirm from 'src/pages/SubscribeConfirm';
import SubscribeFail from 'src/pages/SubscribeFail';
import SignIn from 'src/pages/SignIn';
import NoPage from 'src/pages/NoPage';
import OauthResultApp from 'src/pages/OauthResultApp';
import OauthResultExtension from 'src/pages/OauthResultExtension';

export default function Router() {
	const element = useRoutes([
		{
			element: <DefaultLayout />,
			children: [
				{ path: '/', element: <Dashboard /> },
				{ path: 'dashboard', element: <Dashboard /> },
				{ path: 'job-tracker', element: <JobTracker /> },
				{ path: 'account', element: <Account /> },
				// { path: 'resume-builder', element: <ResumeBuilder /> },
				{ path: 'coupon/:id', element: <Coupon /> },
				{ path: 'payment/confirm/:id', element: <PaymentConfirm /> },
				{ path: 'subscribe/confirm', element: <SubscribeConfirm /> },
				{ path: 'subscribe/fail', element: <SubscribeFail /> },
			],
		},
		{
			path: '/public',
			element: <PublicLayout />,
			children: [
				{ path: 'dashboard/:userId', element: <Dashboard isPublic={true} /> },
				{
					path: 'dashboard/:userId/jobfit/:jobfithash',
					element: <JobFitInsight isPublic={true} />,
				},
			],
		},
		{ path: '/sign-in', element: <SignIn /> },
		{ path: '/oauth/result/app', element: <OauthResultApp /> },
		{ path: '/oauth/result/extension', element: <OauthResultExtension /> },
		{ path: '*', element: <NoPage /> },
	]);

	return element;
}
