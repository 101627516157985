import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMemo } from 'react';
import styles from './Resume.module.scss';
import { getDuration, formatDuration, getStartDate, getEndDate } from './utils';
import { Divider } from '@mui/material';

const Item = ({ item = {}, isLast }) => {
	const startDate = useMemo(() => {
		return getStartDate(item?.startYear, item?.startMonth);
	}, [item?.startYear, item?.startMonth]);

	const endDate = useMemo(() => {
		return getEndDate(item?.isCurrent, item?.endYear, item?.endMonth);
	}, [item]);

	const duration = useMemo(() => {
		return getDuration(
			item.startYear,
			item.startMonth,
			item.endYear,
			item.endMonth,
			item.isCurrent
		);
	}, [item]);

	return (
		<>
			<div className="hover:bg-slate-100 p-2">
				<div className={styles.company}>
					{item?.companyName}
					{item?.jobType ? ` · ${item?.jobType}` : ''}
				</div>
				<div className={`${styles.date}`}>
					{startDate} - {endDate} · {duration}
				</div>
				<span className={`${styles.location}`}>{item?.location}</span>
				<div className="basis-4"></div>
				<div
					className={`${styles.description}`}
					dangerouslySetInnerHTML={{ __html: item?.description }}
				/>
			</div>
			{isLast ? <></> : <Divider />}
		</>
	);
};

export default function Experience({ experiences = [] }) {
	return (
		<Accordion defaultExpanded>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="summary"
				id="summary"
			>
				<div
					style={{ fontSize: '1.2rem', fontWeight: '700', color: '#334155' }}
				>
					Work Experience
				</div>
			</AccordionSummary>
			<AccordionDetails>
				{experiences?.map((item, index) => (
					<Item
						item={item}
						key={index}
						isLast={experiences.length - 1 === index}
					/>
				))}
			</AccordionDetails>
		</Accordion>
	);
}
