import { Box } from '@mui/material';

function JobPost({ applyUrl, jobData = {} }) {
	console.log('JobPost -> jobData', applyUrl);
	return (
		<>
			<iframe
				style={{ width: '100%', height: '75vh', border: 'none' }}
				src={applyUrl}
				title="Job Posting Page"
			></iframe>
		</>
	);
}

export default JobPost;
