import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './Resume.module.scss';

export default function ContactInfo({ profile = {} }) {
	return (
		<Accordion defaultExpanded>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel3-content"
				id="panel3-header"
			>
				<div
					style={{ fontSize: '1.2rem', fontWeight: '700', color: '#334155' }}
				>
					Contact Information
				</div>
			</AccordionSummary>
			<AccordionDetails>
				<div className="flex flex-col gap-y-1">
					<div className="grid grid-cols-[120px_1fr_120px_1fr]">
						<span className={styles.label}>First Name:</span>
						<span className={styles.value}>{profile?.firstName}</span>
						<span className={`${styles.label}`}>Last Name:</span>
						<span className={styles.value}>{profile?.lastName}</span>
					</div>
					<div className="grid grid-cols-[120px_1fr]">
						<span className={styles.label}>Address:</span>
						<span className={styles.value}>{profile?.address}</span>
					</div>
					<div className="grid grid-cols-[120px_1fr_120px_1fr]">
						<span className={styles.label}>City:</span>
						<span className={styles.value}>{profile?.city}</span>
						<span className={styles.label}>State:</span>
						<span className={styles.value}>{profile?.state}</span>
					</div>
					<div className="grid grid-cols-[120px_1fr]">
						<span className={styles.label}>Postal Code:</span>
						<span className={styles.value}>{profile?.zipCode}</span>
					</div>
					<div className="grid grid-cols-[120px_1fr_120px_1fr]">
						<span className={styles.label}>Email Address:</span>
						<span className={`${styles.value} break-all block w-5/6`}>
							{profile?.email}
						</span>
						<span className={`${styles.label}`}>Phone:</span>
						<span className={styles.value}>{profile?.phone}</span>
					</div>
					<div className="grid grid-cols-[120px_1fr]">
						<span className={styles.label}>URL:</span>
						<div className="flex flex-col gapy-y-2">
							{!!profile?.urls &&
								profile?.urls.map((url, index) => (
									<span
										key={index}
										className={styles.url}
										onClick={() => {
											window.open(url, '_blank');
										}}
									>
										{url}
									</span>
								))}
						</div>
					</div>
				</div>
			</AccordionDetails>
		</Accordion>
	);
}
