import ApiFactory from 'src/api/ApiFactory';

const api = {
	listPrompt({ query, or, field, sortby, order, limit, offset }) {
		const apiInstance = ApiFactory.create();
		const params = { query, or, field, sortby, order, limit, offset };
		return apiInstance.get(`/prompt`, { params });
	},
	listUserPrompt({ query, or, field, sortby, order, limit, offset, include }) {
		const apiInstance = ApiFactory.create();
		const params = { query, or, field, sortby, order, limit, offset, include };
		return apiInstance.get(`/prompt/user`, { params });
	},
	createUserPrompt({ userId, promptId, answer }) {
		const apiInstance = ApiFactory.create();
		const data = {
			userId,
			promptId,
			answer,
		};
		return apiInstance.post(`/prompt/user`, data);
	},
	deleteUserPrompt(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.delete(`/prompt/user/${id}`);
	},
	updateUserPrompt(id, { answer }) {
		const apiInstance = ApiFactory.create();
		const data = { id, answer };
		return apiInstance.put(`/prompt/user`, data);
	},
};

export default api;
