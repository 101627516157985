import React, { useEffect, useState, useMemo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Prompts from './components/Prompts';
import promptApi from 'src/api/prompt.api';
import { Link, useLocation } from 'react-router-dom';
import {
	Grid,
	Paper,
	Typography,
	TextField,
	FormHelperText,
	FormControl,
	CircularProgress,
	Button,
	Divider,
	IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useRequest } from 'ahooks';
import { useForm } from 'react-hook-form';
import userAuthStore from '../../../../stores/auth.store';
import useToast from 'src/components/Toast/useToast';
import { DeleteForever, Refresh } from '@mui/icons-material';

const EditPromptItem = ({
	item = {},
	getUserPrompts = () => {},
	isPublicPage = false,
}) => {
	const [editStatus, setEditStatus] = useState(false);
	const userId = userAuthStore((state) => state.userId);
	const { showSuccess, showError } = useToast();

	const { loading: loadingUpdateUserPrompts, run: updatePrompt } = useRequest(
		(answer) =>
			promptApi.updateUserPrompt(item.id, {
				answer,
			}),
		{
			manual: true,
			onSuccess: () => {
				showSuccess('Prompt Updated');
				setEditStatus(false);
				getUserPrompts();
			},
			onError: (error) => {
				console.error(error);
				showError('Something went wrong');
			},
		}
	);

	const { loading: loadingDeleteUserPrompts, run: deletePrompt } = useRequest(
		() => promptApi.deleteUserPrompt(item.id),
		{
			manual: true,
			onSuccess: () => {
				showSuccess('Prompt Deleted');
				setEditStatus(false);
				getUserPrompts();
			},
			onError: (error) => {
				console.error(error);
				showError('Something went wrong');
			},
		}
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();

	const onSubmit = (data) => {
		updatePrompt(data.answer);
	};

	const loading = loadingDeleteUserPrompts || loadingUpdateUserPrompts;

	return (
		<Grid item xs={12} sm={4} md={4}>
			<Paper
				sx={{
					p: 2,
					minHeight: '300px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}
			>
				<div className="flex justify-between align-top gap-2">
					<Typography variant="h6" gutterBottom>
						{item?.promptDetail?.promptName}
					</Typography>
					{editStatus ? (
						<div>
							<IconButton color="error" onClick={() => deletePrompt()}>
								<DeleteForever />
							</IconButton>
						</div>
					) : (
						<></>
					)}
				</div>
				{editStatus ? (
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="flex flex-col gap-2">
							<FormControl>
								<TextField
									label="Answer"
									multiline
									slotProps={{
										input: {
											readOnly: loading,
										},
									}}
									rows={4}
									variant="outlined"
									error={!!errors?.answer}
									{...register('answer', { required: true })}
								/>
							</FormControl>
							<div className="flex gap-2 justify-between">
								<LoadingButton
									variant="outlined"
									color="error"
									loading={loading}
									onClick={() => setEditStatus(false)}
								>
									CANCEL
								</LoadingButton>
								<LoadingButton
									loading={loading}
									type="submit"
									variant="outlined"
								>
									SAVE
								</LoadingButton>
							</div>
						</div>
					</form>
				) : (
					<div className="flex flex-col gap-2 justify-between flex-grow">
						<div className="p-2 border border-dashed border-slate-300 h-full flex flex-grow">
							<Typography variant="subtitle" gutterBottom>
								{item?.answer}
							</Typography>
						</div>
						{!isPublicPage && (
							<div className="flex justify-end">
								<IconButton
									color="primary"
									aria-label="edit"
									onClick={() => {
										setValue('answer', item.answer);
										setEditStatus(true);
									}}
								>
									<EditOutlinedIcon />
								</IconButton>
							</div>
						)}
					</div>
				)}
			</Paper>
		</Grid>
	);
};

const NewPromptItem = ({
	item = {},
	getUserPrompts = () => {},
	handleNext = () => {},
	showNextBtn = false,
}) => {
	const [editStatus, setEditStatus] = useState(false);
	const userId = userAuthStore((state) => state.userId);
	const { showSuccess, showError } = useToast();

	const { loading: loadingUserPrompts, run: addPrompt } = useRequest(
		(answer) =>
			promptApi.createUserPrompt({
				userId,
				promptId: item.id,
				answer,
			}),
		{
			manual: true,
			onSuccess: (res) => {
				showSuccess('Prompt Updated');
				setEditStatus(false);
				getUserPrompts();
			},
			onError: (error) => {
				console.error(error);
				showError('Something went wrong');
			},
		}
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	const onSubmit = (data) => {
		addPrompt(data.answer);
	};

	return (
		<Grid item xs={12} sm={4} md={4}>
			<Paper
				sx={{
					p: 2,
					minHeight: '300px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}
			>
				<Typography variant="h6" gutterBottom onClick={() => getUserPrompts()}>
					{item.promptName}
				</Typography>
				{editStatus ? (
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="flex flex-col gap-2">
							<FormControl>
								<TextField
									label="Answer"
									multiline
									slotProps={{
										input: {
											readOnly: loadingUserPrompts,
										},
									}}
									rows={4}
									variant="outlined"
									error={!!errors?.answer}
									{...register('answer', { required: true })}
								/>
							</FormControl>

							<div className="flex gap-2 justify-between">
								<LoadingButton
									variant="outlined"
									color="error"
									loading={loadingUserPrompts}
									onClick={() => setEditStatus(false)}
								>
									CANCEL
								</LoadingButton>
								<LoadingButton
									loading={loadingUserPrompts}
									type="submit"
									variant="outlined"
								>
									SAVE
								</LoadingButton>
							</div>
						</div>
					</form>
				) : (
					<div className="flex flex-col gap-2">
						<LoadingButton
							variant="outlined"
							fullWidth
							onClick={() => {
								reset();
								setEditStatus(true);
							}}
						>
							ANSWER NOW <EditOutlinedIcon />
						</LoadingButton>
						{showNextBtn ? (
							<LoadingButton
								variant="text"
								fullWidth
								onClick={() => handleNext()}
							>
								NEXT QUESTION <Refresh />
							</LoadingButton>
						) : (
							<></>
						)}
					</div>
				)}
			</Paper>
		</Grid>
	);
};

export default function Prompt() {
	const [nextPromptIndex, setNextPromptIndex] = useState(2);
	const [shownPrompts, setShownPrompts] = useState([]);

	const location = useLocation();
	const isPublicPage = useMemo(
		() => location.pathname.includes('public') || false,
		[location]
	);

	const { loading: loadingPromptList, data: promptListData } = useRequest(() =>
		promptApi.listPrompt({
			sortby: 'createdAt',
			order: 'asc',
			limit: 0,
		})
	);
	const {
		loading: loadingUserPrompts,
		data: userPromptListData,
		run: getUserPrompts,
	} = useRequest(() =>
		promptApi.listUserPrompt({
			sortby: 'createdAt',
			order: 'desc',
			limit: 0,
		})
	);

	const userPromptList = useMemo(() => {
		const _userPromptList = userPromptListData?.data?.rows || [];
		const _promptList = promptListData?.data?.rows || [];

		return _userPromptList.map((i) => ({
			...i,
			promptDetail: _.find(_promptList, { id: i.promptId }),
		}));
	}, [promptListData, userPromptListData]);

	const promptList = useMemo(
		() =>
			_.chain(promptListData?.data?.rows || [])
				.filter((i) => !_.find(userPromptList, { promptId: i.id }))
				.value(),
		[promptListData, userPromptList]
	);

	useEffect(() => {
		const minSet = userPromptList.length > 1 ? 1 : 3 - userPromptList.length;
		setShownPrompts(promptList.slice(0, minSet));
	}, [promptList, userPromptList]);

	const handleNext = () => {
		const nextIndex = nextPromptIndex + 1;
		// If there's a next item, update the visibleItems array
		if (nextIndex < promptList.length) {
			const updatedItems = [...shownPrompts];
			updatedItems[shownPrompts.length - 1] = promptList[nextIndex]; // Replace the third item with the next one
			setShownPrompts(updatedItems);
			setNextPromptIndex(nextIndex);
		} else {
			const updatedItems = [...shownPrompts];
			updatedItems[shownPrompts.length - 1] =
				promptList[shownPrompts.length - 1]; // Replace the third item with the next one
			setShownPrompts(updatedItems);
			setNextPromptIndex(shownPrompts.length - 1);
		}
	};

	const loading = loadingPromptList || loadingUserPrompts;

	return (
		<>
			<Grid container spacing={2} pb={2}>
				{/* answered section */}
				{userPromptList.map((item, index) => (
					<EditPromptItem
						item={item}
						key={index}
						getUserPrompts={getUserPrompts}
						isPublicPage={isPublicPage}
					/>
				))}
				{!isPublicPage &&
					shownPrompts.map((item, index) => (
						<NewPromptItem
							item={item}
							key={index}
							getUserPrompts={getUserPrompts}
							handleNext={handleNext}
							showNextBtn={
								!!(index === shownPrompts.length - 1) && promptList.length !== 1
							}
						/>
					))}
			</Grid>
		</>
	);
}
