import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Typography, Stack } from '@mui/material';

function KeywordDetail({ skills = [] }) {
	if (skills.length === 0)
		return (
			<Typography variant="body2">
				No keywords of this job, please click extract key skills first.
			</Typography>
		);
	return (
		<Stack direction="row" flexWrap="wrap" spacing={1} useFlexGap>
			{skills.map((skill, index) => (
				<Stack direction="row" key={index}>
					<CheckCircleRoundedIcon
						fontSize="small"
						color={skill.checked ? 'success' : 'disabled'}
						sx={{
							marginRight: '2px',
						}}
					/>
					<Typography variant="caption" sx={{ marginTop: '2px' }}>
						{skill.keySkill}
					</Typography>
				</Stack>
			))}
		</Stack>
	);
}

export default KeywordDetail;
