import dayjs from 'dayjs';

export function getStartDate(startYear, startMonth) {
	return dayjs(`${startYear}-${startMonth}`).format('MMM YYYY');
}

export function getEndDate(isCurrent, endYear, endMonth) {
	if (isCurrent) return 'current';
	return dayjs(`${endYear}-${endMonth}`).format('MMM YYYY');
}

export function formatDuration(input) {
	const inputs = input.split(' ');
	let result = '';
	if (parseInt(inputs[0]) === 1) {
		result += inputs[0] + ' yr';
	} else if (parseInt(inputs[0]) > 1) {
		result += inputs[0] + ' yrs';
	}

	if (parseInt(inputs[1]) === 1) {
		result += ' ' + inputs[1] + ' mo';
	} else if (parseInt(inputs[1]) > 1) {
		result += ' ' + inputs[1] + ' mos';
	}
	if (parseInt(inputs[0]) === 0 && parseInt(inputs[1]) === 0) {
		result += '0 mo';
	}
	return result;
}

export function getDuration(
	startYear,
	startMonth,
	endYear,
	endMonth,
	isCurrent
) {
	const currentEndYear = !isCurrent ? endYear : dayjs().format('YYYY');
	const currentEndMonth = !isCurrent ? endMonth : dayjs().format('MM');
	const yearGap = parseInt(currentEndYear) - parseInt(startYear);
	const monthGap = parseInt(currentEndMonth) - parseInt(startMonth);

	return formatDuration(`${yearGap} ${monthGap}`);
}
