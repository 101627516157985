import ApiFactory from 'src/api/ApiFactory';
import { Buffer } from 'buffer';

const api = {
	listJob({
		query,
		or,
		field,
		sortby,
		order,
		limit,
		offset,
		contain,
		keyword,
		like,
	}) {
		const apiInstance = ApiFactory.create();
		const params = {
			query,
			or,
			field,
			sortby,
			order,
			limit,
			offset,
			contain: contain ? Buffer.from(contain).toString('base64') : '',
			keyword,
			like,
		};
		return apiInstance.get(`/job`, { params });
	},
	getJob(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/job/${id}`);
	},
	createJob({
		userId,
		title,
		company,
		location,
		salary,
		experience,
		labels,
		description,
		status,
		postUrl,
		jobType,
		sponsors,
	}) {
		const apiInstance = ApiFactory.create();
		const data = {
			userId,
			title,
			company,
			location,
			salary,
			experience,
			labels,
			description,
			status,
			postUrl,
			jobType,
			sponsors,
		};
		return apiInstance.post(`/job`, data);
	},
	updateJob(
		id,
		{
			title,
			company,
			location,
			salary,
			experience,
			labels,
			description,
			status,
			postUrl,
			jobType,
			sponsors,
		}
	) {
		const apiInstance = ApiFactory.create();
		const data = {
			id,
			title,
			company,
			location,
			salary,
			experience,
			labels,
			description,
			status,
			postUrl,
			jobType,
			sponsors,
		};
		return apiInstance.put(`/job`, data);
	},
	batchUpdateJob(ids, { status }) {
		const apiInstance = ApiFactory.create();
		const data = { ids, payload: { status } };
		return apiInstance.put(`/job/batch`, data);
	},
	listNote({ query, or, field, sortby, order, limit, offset }) {
		const apiInstance = ApiFactory.create();
		const params = { query, or, field, sortby, order, limit, offset };
		return apiInstance.get(`/job/note`, { params });
	},
	updateNote({ id, description }) {
		const apiInstance = ApiFactory.create();
		const data = { id, description };
		return apiInstance.put(`/job/note`, data);
	},
	createNote({ userId, jobId, description }) {
		const apiInstance = ApiFactory.create();
		const data = { userId, jobId, description };
		return apiInstance.post(`/job/note`, data);
	},
	getNote(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/job/note/${id}`);
	},
	deleteNote(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.delete(`/job/note/${id}`);
	},
	listContact({ query, or, field, sortby, order, limit, offset }) {
		const apiInstance = ApiFactory.create();
		const params = { query, or, field, sortby, order, limit, offset };
		return apiInstance.get(`/job/contact`, { params });
	},
	updateContact({ id, name, title, email, phone }) {
		const apiInstance = ApiFactory.create();
		const data = { id, name, title, email, phone };
		return apiInstance.put(`/job/contact`, data);
	},
	createContact({ userId, jobId, name, title, email, phone }) {
		const apiInstance = ApiFactory.create();
		const data = { userId, jobId, name, title, email, phone };
		return apiInstance.post(`/job/contact`, data);
	},
	getContact(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/job/contact/${id}`);
	},
	deleteContact(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.delete(`/job/contact/${id}`);
	},
	getResume(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/job/${id}/resume`);
	},
	getCoverLetter(id) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/job/${id}/coverLetter`);
	},
	getStatusTracking({ startDate, endDate }) {
		const apiInstance = ApiFactory.create();
		const params = { startDate, endDate };
		return apiInstance.get(`/job/status/tracking`, { params });
	},
	getStatusTrackingSummary() {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/job/status/tracking/total`);
	},
	getTitleTracking({ startDate, endDate }) {
		const apiInstance = ApiFactory.create();
		const params = { startDate, endDate };
		return apiInstance.get(`/job/title/tracking`, { params });
	},
};
export default api;
