import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';

function fixIssueLineEditor(str = '') {
	str = str.replace(new RegExp('<p><br></p>' + '$'), '');
	return str;
}

export default function Summary({ summary = {} }) {
	return (
		<Accordion defaultExpanded>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="summary"
				id="summary"
			>
				<div
					style={{ fontSize: '1.2rem', fontWeight: '700', color: '#334155' }}
				>
					Professional Summaries
				</div>
			</AccordionSummary>
			<AccordionDetails>
				<Box
					component="div"
					dangerouslySetInnerHTML={{
						__html: fixIssueLineEditor(summary?.description),
					}}
					sx={{
						wordWrap: 'break-word',
					}}
				/>
			</AccordionDetails>
		</Accordion>
	);
}
