import {
	Box,
	Paper,
	Typography,
	IconButton,
	FormControl,
	MenuItem,
	InputLabel,
	Select,
	Button,
	Grid,
	TextField,
	Divider,
} from '@mui/material';
import ExportResumePDFDialog from 'src/pages/ResumeBuilder/components/ResumePDF/ExportResumePDFDialog.js';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LanguageIcon from '@mui/icons-material/Language';
import { useToggle } from 'ahooks';
import React, { useMemo, useState, useEffect } from 'react';
import { getDuration } from 'src/libs/utils';
import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { US_STATE_CITY } from 'src/constants/dashboard';

export default function About({
	primaryResume,
	updateResume,
	setOpenUploadDialog,
}) {
	const renderMenuItems = (item) => (
		<MenuItem key={item} value={item}>
			{item}
		</MenuItem>
	);
	const LocationSelect = ({ level, options, locationVariable }) => {
		return (
			<FormControl size="small" fullWidth>
				<InputLabel id={level} style={{ zIndex: 0 }}>
					{level}
				</InputLabel>
				<Select
					name={level}
					label={level}
					value={locationVariable}
					onChange={(e) => {
						handleInputChange(e.target.value, 'profile', level?.toLowerCase());
					}}
				>
					{options?.map((option) => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		);
	};
	const EditButton = () => {
		return (
			<Box
				sx={{
					marginTop: 'auto',
					justifyContent: 'flex-end',
					display: location.pathname.includes('public') ? 'none' : 'flex',
				}}
			>
				{isEditing ? (
					<Button
						color="primary"
						aria-label="save-about"
						variant="contained"
						size="small"
						onClick={onAboutSave}
					>
						Save
					</Button>
				) : (
					<IconButton
						color="primary"
						aria-label="edit-about"
						onClick={setIsEditing}
					>
						<EditOutlinedIcon />
					</IconButton>
				)}
			</Box>
		);
	};
	function SelectYear({
		name,
		label,
		value,
		onChange,
		years,
		index,
		experience,
	}) {
		let filteredYears;
		if (label === 'End Year') {
			filteredYears = years.filter((year) => year >= experience.startYear);
		} else {
			filteredYears = years;
		}
		return (
			<>
				<FormControl size="small" fullWidth>
					<InputLabel id={label} style={{ zIndex: 0 }}>
						{label}
					</InputLabel>
					<Select
						name={name}
						label={label}
						value={value}
						onChange={(e) => {
							onChange(e.target.value, index);
						}}
						fullWidth
						size="small"
					>
						{filteredYears.map(renderMenuItems)}
					</Select>
				</FormControl>
			</>
		);
	}
	function SelectMonth({
		name,
		label,
		value,
		onChange,
		months,
		index,
		experience,
	}) {
		const filteredMonths = useMemo(() => {
			if (label === 'End Month') {
				return experience.startYear === experience.endYear
					? months.filter((month) => month >= experience.startMonth)
					: months;
			} else {
				return months;
			}
		}, [
			experience.startYear,
			experience.endYear,
			experience.startMonth,
			label,
		]);

		return (
			<>
				<FormControl size="small" fullWidth>
					<InputLabel id={label} style={{ zIndex: 0 }}>
						{label}
					</InputLabel>
					<Select
						name={name}
						label={label}
						value={value}
						onChange={(e) => {
							onChange(e.target.value, index);
						}}
						fullWidth
						size="small"
					>
						{filteredMonths.map(renderMenuItems)}
					</Select>
				</FormControl>
			</>
		);
	}
	const [showExportPDFDialog, setShowExportPDFDialog] = useState(false);
	const [primaryResumeCopy, setPrimaryResumeCopy] = useState({
		...primaryResume,
	});
	const [email, setEmail] = useState(primaryResumeCopy?.profile?.email || '');
	const [experiences, setExperiences] = useState(
		primaryResumeCopy?.experiences?.slice(0, 2) || []
	);
	const [urls, setUrls] = useState(primaryResumeCopy?.urls || []);
	const [education, setEducation] = useState(
		primaryResumeCopy?.educations?.[0]?.schoolName || ''
	);
	const [state, setState] = useState(primaryResumeCopy?.profile?.state || '');
	const [city, setCity] = useState(primaryResumeCopy?.profile?.city || '');
	const [avatar, setAvatar] = useState(primaryResumeCopy?.Avatar || '');
	const [linkedIn, setLinkedIn] = useState('');
	const [blog, setBlog] = useState([]);
	const [firstName, setFirstName] = useState(
		primaryResumeCopy?.profile?.firstName || ''
	);
	const [lastName, setLastName] = useState(
		primaryResumeCopy?.profile?.lastName || ''
	);
	const name = useMemo(
		() => [firstName, lastName].filter(Boolean).join(' '),
		[firstName, lastName]
	);
	const [isEditing, { toggle: setIsEditing }] = useToggle(false);
	const [cities, setCities] = useState([]);
	const years = useMemo(
		() =>
			Array.from({ length: 50 }, (_, i) =>
				String(new Date().getFullYear() - i)
			),
		[]
	);
	const months = useMemo(
		() =>
			Array.from({ length: 12 }, (_, i) => (i < 9 ? `0${i + 1}` : `${i + 1}`)),
		[]
	);
	useEffect(() => {
		const fetchData = async () => {
			const { profile = {} } = primaryResumeCopy || {};
			const {
				firstName = '',
				lastName = '',
				email = '',
				state = '',
				city = '',
				urls = [],
			} = profile;
			setUrls(urls);
			setFirstName(firstName);
			setLastName(lastName);
			setEmail(email);
			setExperiences(primaryResumeCopy?.experiences?.slice(0, 2) || []);
			setEducation(primaryResumeCopy?.educations?.[0]?.schoolName || '');
			setState(state);
			setCity(city);
			setCities(US_STATE_CITY[state]);
			setAvatar(primaryResumeCopy?.Avatar || '');
			const linkedInUrl = urls.find((url) =>
				url.toLowerCase().includes('linkedin')
			);
			setLinkedIn(linkedInUrl || '');

			const otherUrls = urls.filter(
				(url) => !url.toLowerCase().includes('linkedin')
			);
			setBlog(otherUrls);
		};

		fetchData();
	}, [primaryResumeCopy]);

	const handleInputChange = (value, fieldName, childFieldName) => {
		const updatedResume = { ...primaryResumeCopy };
		childFieldName
			? (updatedResume[fieldName][childFieldName] = value)
			: (updatedResume[fieldName] = value);
		if (childFieldName === 'state') {
			setCity('');
			setCities(US_STATE_CITY[value] || []);
			updatedResume['profile']['city'] = '';
		}
		setPrimaryResumeCopy(updatedResume);
	};
	const handleArrayInputChange = (
		value,
		fieldName,
		arrayIndex,
		childFieldName
	) => {
		const updatedResume = { ...primaryResumeCopy };
		if (childFieldName) {
			updatedResume[fieldName][arrayIndex][childFieldName] = value;
			if (childFieldName === 'endMonth' || childFieldName === 'endYear') {
				updatedResume[fieldName][arrayIndex]['isCurrent'] = false;
			}
		} else {
			updatedResume[fieldName][arrayIndex] = value;
		}
		setPrimaryResumeCopy(updatedResume);
	};
	const handleUrlsChange = (value, arrayIndex) => {
		const updatedResume = { ...primaryResumeCopy };
		updatedResume['profile']['urls'][arrayIndex] = value;
		setPrimaryResumeCopy(updatedResume);
	};
	const onAboutSave = () => {
		const updatedResume = { ...primaryResumeCopy };
		updateResume(updatedResume);
		setIsEditing();
	};

	useEffect(() => {
		setPrimaryResumeCopy(primaryResume);
	}, [primaryResume]);

	return (
		<Paper
			sx={{
				p: 2,
				display: 'flex',
				flexDirection: 'column',
				heigh: '500px',
				overflowY: 'auto',
			}}
		>
			<Typography variant="h6" gutterBottom>
				About me
			</Typography>
			{primaryResume?.id ? (
				!isEditing ? (
					<Grid container spacing={2}>
						<Grid
							container
							item
							direction={'column'}
							justifyContent="center"
							alignItems="center"
							spacing={2}
							xs={7}
							sm={7}
						>
							<Grid item>
								{avatar ? (
									<Avatar
										alt={name}
										src={avatar}
										sx={{ width: 56, height: 56 }}
									/>
								) : (
									<Avatar sx={{ width: 56, height: 56 }}>{name[0]}</Avatar>
								)}
							</Grid>
							<Grid item>
								<Typography variant="h5" gutterBottom>
									{name}
								</Typography>
							</Grid>

							<Grid
								container
								item
								direction={'column'}
								justifyContent="center"
								alignItems="center"
							>
								{experiences.map((item, index) => (
									<Grid key={index} item>
										{getDuration(
											item.startYear,
											item.startMonth,
											item.endYear,
											item.endMonth,
											item.isCurrent
										)}{' '}
										as {item.position}
									</Grid>
								))}
							</Grid>

							<Grid
								item
								sx={{
									textAlign: 'center',
									color: 'text.secondary',
								}}
							>
								<Typography variant="body2" gutterBottom>
									<SchoolOutlinedIcon sx={{ mr: 0.5 }} />
									{education}
								</Typography>
								<Typography variant="body2" gutterBottom>
									{city && state ? (
										<>
											<LocationOnOutlinedIcon />
											{city}, {state}
										</>
									) : state ? (
										<>
											<LocationOnOutlinedIcon />
											{state}
										</>
									) : (
										<>
											<LocationOnOutlinedIcon /> Unknown
										</>
									)}
								</Typography>
							</Grid>
							<Grid
								container
								item
								spacing={2}
								justifyContent="center"
								alignItems="center"
							>
								<Grid item>
									<Button
										aria-label="linkedIn"
										href={linkedIn}
										component="a"
										target="_blank"
									>
										<LinkedInIcon fontSize="large" />
									</Button>
								</Grid>

								<Grid item>
									<Button
										aria-label="email"
										component="a"
										target="_blank"
										href={`mailto:${email}`}
									>
										<MailOutlineIcon
											fontSize="medium"
											sx={{
												backgroundColor: 'black',
												width: '26.25px',
												height: '26.25px',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												borderRadius: '3px',
												marginLeft: '4px',
												color: 'white',
											}}
										/>
									</Button>
								</Grid>

								{blog.map((element, index) => (
									<Grid item key={index}>
										<Button
											aria-label="website"
											href={element}
											component="a"
											target="_blank"
										>
											<LanguageIcon
												fontSize="medium"
												sx={{
													backgroundColor: 'black',
													width: '26.25px',
													height: '26.25px',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: '3px',
													marginLeft: '4px',
													color: 'white',
												}}
											/>
										</Button>
									</Grid>
								))}
							</Grid>

							<Grid item justifyContent="center" alignItems="center">
								<Button
									disabled={!primaryResumeCopy?.profile}
									variant="outlined"
									onClick={() => {
										setShowExportPDFDialog(true);
									}}
								>
									VIEW RESUME
								</Button>
								{primaryResumeCopy?.resumeName && (
									<ExportResumePDFDialog
										showExportPDFDialog={showExportPDFDialog}
										setShowExportPDFDialog={setShowExportPDFDialog}
										resume={primaryResumeCopy}
									/>
								)}
							</Grid>
						</Grid>
						<Grid container item spacing={2} xs={5} sm={5}>
							<Grid item>{primaryResumeCopy?.summary?.description}</Grid>
						</Grid>
					</Grid>
				) : (
					<Grid container spacing={2}>
						<Grid container item spacing={2} xs={6} sm={6}>
							<Grid item xs={6}>
								<FormControl size="small" fullWidth>
									<TextField
										size="small"
										name="firstName"
										label="First Name"
										value={firstName}
										onChange={(e) => {
											handleInputChange(e.target.value, 'profile', 'firstName');
										}}
										style={{ zIndex: 0 }}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl size="small" fullWidth>
									<TextField
										size="small"
										name="lastName"
										label="Last Name"
										value={lastName}
										onChange={(e) => {
											handleInputChange(e.target.value, 'profile', 'lastName');
										}}
										style={{ zIndex: 0 }}
									/>
								</FormControl>
							</Grid>

							<Grid item xs={12}>
								<FormControl size="small" fullWidth>
									<TextField
										size="small"
										name="education"
										label="Education"
										value={education}
										onChange={(e) => {
											handleArrayInputChange(
												e.target.value,
												'educations',
												0,
												'schoolName'
											);
										}}
										style={{ zIndex: 0 }}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<LocationSelect
									level="State"
									options={Object.keys(US_STATE_CITY)}
									locationVariable={state}
								/>
							</Grid>
							<Grid item xs={6}>
								<LocationSelect
									level="City"
									options={cities}
									locationVariable={city}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl size="small" fullWidth>
									<TextField
										size="small"
										name="email"
										label="Email"
										value={email}
										onChange={(e) => {
											handleInputChange(e.target.value, 'profile', 'email');
										}}
										style={{ zIndex: 0 }}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid container item spacing={2}>
								{experiences.map((element, index) => (
									<Grid key={index} container item xs={12} spacing={2}>
										<Grid item xs={12}>
											<FormControl fullWidth>
												<TextField
													size="small"
													name="title"
													label="Title"
													value={element.position}
													onChange={(e) => {
														handleArrayInputChange(
															e.target.value,
															'experiences',
															index,
															'position'
														);
													}}
													style={{ zIndex: 0 }}
												/>
											</FormControl>
										</Grid>
										<Grid container item spacing={2} xs={12}>
											<Grid item xs={6} sm={6}>
												<SelectYear
													name="startYear"
													label="Start Year"
													value={element.startYear}
													onChange={(value) => {
														handleArrayInputChange(
															value,
															'experiences',
															index,
															'startYear'
														);
													}}
													years={years}
													index={index}
													experience={element}
												/>
											</Grid>

											<Grid item xs={6} sm={6}>
												<SelectMonth
													name="startMonth"
													label="Start Month"
													value={element.startMonth}
													onChange={(value) => {
														handleArrayInputChange(
															value,
															'experiences',
															index,
															'startMonth'
														);
													}}
													months={months}
													index={index}
													experience={element}
												/>
											</Grid>
										</Grid>
										<Grid container item spacing={2} xs={12}>
											<Grid item xs={6} sm={6}>
												<SelectYear
													name="endYear"
													label="End Year"
													value={element.endYear}
													onChange={(value) => {
														handleArrayInputChange(
															value,
															'experiences',
															index,
															'endYear'
														);
													}}
													years={years}
													index={index}
													experience={element}
												/>
											</Grid>

											<Grid item xs={6} sm={6}>
												<SelectMonth
													name="endMonth"
													label="End Month"
													value={element.endMonth}
													onChange={(value) => {
														handleArrayInputChange(
															value,
															'experiences',
															index,
															'endMonth'
														);
													}}
													months={months}
													index={index}
													experience={element}
												/>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
						<Grid
							container
							item
							spacing={2}
							xs={6}
							sm={6}
							alignContent="flex-start"
						>
							<Grid item xs={12} sm={12}>
								<TextField
									name="description"
									size="medium"
									required
									value={primaryResumeCopy?.summary?.description}
									onChange={(e) => {
										handleInputChange(e.target.value, 'summary', 'description');
									}}
									label="Description"
									variant="outlined"
									multiline
									fullWidth
									rows={10}
									style={{ zIndex: 0 }}
								/>
							</Grid>

							{urls.map((element, index) => (
								<Grid key={index} item xs={12} sm={12}>
									<FormControl size="small" fullWidth>
										<TextField
											size="small"
											name="url"
											label={`Url${index + 1}`}
											value={element}
											onChange={(e) => {
												handleUrlsChange(e.target.value, index);
											}}
											style={{ zIndex: 0 }}
										/>
									</FormControl>
								</Grid>
							))}
						</Grid>
					</Grid>
				)
			) : (
				<React.Fragment>
					<div className="h-56 mt-8 flex flex-col gap-y-8">
						<span className="text-sm text-slate-700">
							Oops! It seems you haven't uplaod any resume on Jobbly yet. Please
							upload your resume to get started.
						</span>
						<div className="flex flex-row justify-center">
							<Button
								variant="outlined"
								size="small"
								onClick={() => {
									setOpenUploadDialog(true);
								}}
							>
								Upload Resume
							</Button>
						</div>
					</div>
				</React.Fragment>
			)}
			{primaryResume?.id && <EditButton />}
		</Paper>
	);
}
