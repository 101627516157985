import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './Resume.module.scss';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export default function Skills({ skills = [] }) {
	return (
		<Accordion defaultExpanded>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="summary"
				id="summary"
			>
				<div
					style={{ fontSize: '1.2rem', fontWeight: '700', color: '#334155' }}
				>
					Skills
				</div>
			</AccordionSummary>
			<AccordionDetails>
				{skills?.map((skillData) => (
					<div
						key={skillData?.id}
						className={`${styles.item} flex flex-row justify-between items-center`}
					>
						<div className="grow grid grid-cols-[40px_140px_120px] gap-x-8">
							<div className={`flex flex-row gap-x-2 ${styles.location}`}>
								{skillData?.checked ? (
									<CheckIcon size="small" color="default"></CheckIcon>
								) : (
									<CloseIcon size="small" color="default"></CloseIcon>
								)}
							</div>
							<div className={'ellipsis'}>
								<span className={`${styles.company}`}>
									{skillData?.skillName}
								</span>
							</div>
							<Rating
								size="small"
								value={parseFloat(skillData?.value)}
								precision={0.5}
								readOnly
								emptyIcon={
									<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
								}
							/>
						</div>
					</div>
				))}
			</AccordionDetails>
		</Accordion>
	);
}
