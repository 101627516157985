import {
	Box,
	Divider,
	Typography,
	Grid,
	Paper,
	CircularProgress,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Fragment } from 'react';

const mainClass = {
	numberCard: {
		p: 2,
		minHeight: '150px',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		justifyContent: 'center',
	},
};

function JobDashboardComponent({
	user = {},
	certifications = [],
	trackingTotalData = {},
	loadingTrackingTotal = false,
}) {
	// console.log(`certifications`);
	// console.log(certifications);
	return (
		<>
			<Box
				component="div"
				sx={{
					wordWrap: 'break-word',
					// boxShadow: 2,
					border: 1,
					borderColor: '#f0f0f0',
					p: 2,
				}}
			>
				<Accordion
					defaultExpanded
					sx={{
						backgroundColor: 'transparent',
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="ideal"
						id="ideal"
						sx={{
							backgroundColor: 'transparent',
						}}
					>
						<div
							style={{
								fontSize: '1.2rem',
								fontWeight: '700',
								color: '#334155',
							}}
						>
							My Ideal Job
						</div>
					</AccordionSummary>
					<AccordionDetails>
						<div className="grid grid-cols-3 w-full gap-x-4">
							<Typography variant="subtitle1" gutterBottom>
								Location :
							</Typography>
							<Typography
								variant="subtitle1"
								gutterBottom
								className="col-span-2"
							>
								{user?.location?.join(', ')}
							</Typography>
							<Typography variant="subtitle1" gutterBottom>
								Types :
							</Typography>
							<Typography
								variant="subtitle1"
								gutterBottom
								className="col-span-2"
							>
								{user?.jobType?.join(', ')}
							</Typography>
							<Typography variant="subtitle1" gutterBottom>
								Level :
							</Typography>
							<Typography
								variant="subtitle1"
								gutterBottom
								className="col-span-2"
							>
								{user?.jobLevel?.join(', ')}
							</Typography>
							<Typography variant="subtitle1" gutterBottom>
								Work Type :
							</Typography>
							<Typography
								variant="subtitle1"
								gutterBottom
								className="col-span-2"
							>
								{user?.workType?.join(', ')}
							</Typography>
						</div>
					</AccordionDetails>
				</Accordion>
				<Divider />
				<Accordion
					defaultExpanded
					sx={{
						backgroundColor: 'transparent',
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="ideal"
						id="ideal"
						sx={{
							backgroundColor: 'transparent',
						}}
					>
						<div
							style={{
								fontSize: '1.2rem',
								fontWeight: '700',
								color: '#334155',
							}}
						>
							Languages I Speak:
						</div>
					</AccordionSummary>
					<AccordionDetails>
						<Typography variant="subtitle1" gutterBottom className="col-span-2">
							{user?.languages?.join(', ') || '-'}
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Divider />
				<Accordion
					defaultExpanded
					sx={{
						backgroundColor: 'transparent',
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="ideal"
						id="ideal"
						sx={{
							backgroundColor: 'transparent',
						}}
					>
						<div
							style={{
								fontSize: '1.2rem',
								fontWeight: '700',
								color: '#334155',
							}}
						>
							Certifications
						</div>
					</AccordionSummary>
					<AccordionDetails>
						{certifications?.map((item, index) => (
							<Fragment key={index}>
								<Typography
									variant="h6"
									gutterBottom
									className={
										item?.certificationUrl
											? 'col-span-2 cursor-pointer hover:text-[#0f6795]'
											: 'col-span-2'
									}
									onClick={() => {
										if (item?.certificationUrl) {
											window.open(item?.certificationUrl, '_blank');
										}
									}}
								>
									{item?.certificationName}
								</Typography>
								<Typography
									variant="subtitle2"
									gutterBottom
									className="col-span-2"
								>
									{item?.provider} - {item?.issueYear}/{item?.issueMonth}
								</Typography>
							</Fragment>
						))}
					</AccordionDetails>
				</Accordion>
				<Divider />
				<Accordion
					defaultExpanded
					sx={{
						backgroundColor: 'transparent',
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="ideal"
						id="ideal"
						sx={{
							backgroundColor: 'transparent',
						}}
					>
						<div
							style={{
								fontSize: '1.2rem',
								fontWeight: '700',
								color: '#334155',
							}}
						>
							Statistics
						</div>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Paper sx={mainClass.numberCard}>
									<Typography
										variant="h4"
										color="secondary"
										sx={{ fontWeight: 700 }}
									>
										{loadingTrackingTotal ? (
											<CircularProgress />
										) : (
											trackingTotalData?.USAGE || 0
										)}
									</Typography>
									<Typography variant="caption">Tailored Resume</Typography>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper sx={mainClass.numberCard}>
									<Typography
										variant="h4"
										color="secondary"
										sx={{ fontWeight: 700 }}
									>
										{loadingTrackingTotal ? (
											<CircularProgress />
										) : (
											trackingTotalData?.COVER_LETTER_USAGE || 0
										)}
									</Typography>
									<Typography variant="caption">
										Tailored Cover Letter
									</Typography>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper sx={mainClass.numberCard}>
									<Typography
										variant="h4"
										color="primary"
										sx={{ fontWeight: 700 }}
									>
										{loadingTrackingTotal ? (
											<CircularProgress />
										) : (
											trackingTotalData?.APPLY || 0
										)}
									</Typography>
									<Typography variant="caption">Saved Jobs</Typography>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper sx={mainClass.numberCard}>
									<Typography
										variant="h4"
										color="#009688"
										sx={{ fontWeight: 700 }}
									>
										{loadingTrackingTotal ? (
											<CircularProgress />
										) : (
											trackingTotalData?.REQUEST || 0
										)}
									</Typography>
									<Typography variant="caption">Applied Positions</Typography>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper sx={mainClass.numberCard}>
									<Typography
										variant="h4"
										color="#81c784"
										sx={{ fontWeight: 700 }}
									>
										{loadingTrackingTotal ? (
											<CircularProgress />
										) : (
											trackingTotalData?.INTERVIEW || 0
										)}
									</Typography>
									<Typography variant="caption">Interviewing</Typography>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper sx={mainClass.numberCard}>
									<Typography
										variant="h4"
										color="#ff8a65"
										sx={{ fontWeight: 900 }}
									>
										{loadingTrackingTotal ? (
											<CircularProgress />
										) : (
											trackingTotalData?.OFFER || 0
										)}
									</Typography>
									<Typography variant="caption">Offer Received</Typography>
								</Paper>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
				<Divider />
			</Box>
		</>
	);
}

export default JobDashboardComponent;
