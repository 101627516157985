import { Box, Divider, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
function fixIssueLineEditor(str = '') {
	str = str.replace(new RegExp('<p><br></p>' + '$'), '');
	return str;
}

function JobDescriptionComponent({ jobData = {} }) {
	return (
		<>
			<Box
				component="div"
				sx={{
					wordWrap: 'break-word',
					// boxShadow: 2,
					border: 1,
					borderColor: '#f0f0f0',
					p: 2,
				}}
			>
				<div className="grid grid-cols-3 w-full gap-x-4">
					<Typography variant="subtitle1" gutterBottom>
						Title :
					</Typography>
					<Typography variant="subtitle1" gutterBottom className="col-span-2">
						{jobData?.title}
					</Typography>
					<Typography variant="subtitle1" gutterBottom>
						Company :
					</Typography>
					<Typography variant="subtitle1" gutterBottom className="col-span-2">
						{jobData?.company}
					</Typography>
					<Typography variant="subtitle1" gutterBottom>
						Location :
					</Typography>
					<Typography variant="subtitle1" gutterBottom className="col-span-2">
						{jobData?.location}
					</Typography>
					<Typography variant="subtitle1" gutterBottom>
						Type :
					</Typography>
					<Typography variant="subtitle1" gutterBottom className="col-span-2">
						{jobData?.jobType}
					</Typography>
					<Typography variant="subtitle1" gutterBottom>
						Years of Experience :
					</Typography>
					<Typography variant="subtitle1" gutterBottom className="col-span-2">
						{jobData?.experience}
					</Typography>
					<Typography variant="subtitle1" gutterBottom>
						Salary :
					</Typography>
					<Typography variant="subtitle1" gutterBottom className="col-span-2">
						{jobData?.salary || '-'}
					</Typography>
					<Typography variant="subtitle1" gutterBottom>
						Visa Sponsoring :
					</Typography>
					<Typography variant="subtitle1" gutterBottom className="col-span-2">
						{jobData?.sponsors?.join(' , ') || '-'}
					</Typography>
				</div>
				<Divider />
				<Accordion
					defaultExpanded
					sx={{
						backgroundColor: 'transparent',
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="labels"
						id="labels"
						sx={{
							backgroundColor: 'transparent',
						}}
					>
						<div
							style={{
								fontSize: '1.2rem',
								fontWeight: '700',
								color: '#334155',
							}}
						>
							Labels
						</div>
					</AccordionSummary>
					<AccordionDetails>
						<ul className="list-disc pl-4">
							{jobData?.labels?.map((item, index) => (
								<li key={index}>{item}</li>
							))}
						</ul>
					</AccordionDetails>
				</Accordion>
				<Divider />
				<Accordion
					defaultExpanded
					sx={{
						backgroundColor: 'transparent',
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="details"
						id="details"
						sx={{
							backgroundColor: 'transparent',
						}}
					>
						<div
							style={{
								fontSize: '1.2rem',
								fontWeight: '700',
								color: '#334155',
							}}
						>
							Details
						</div>
					</AccordionSummary>
					<AccordionDetails>{jobData?.description}</AccordionDetails>
				</Accordion>
			</Box>
		</>
	);
}

export default JobDescriptionComponent;
