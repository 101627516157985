import ApiFactory from 'src/api/ApiFactory';

const api = {
	getShareHashData(hash) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/sharableLink/hash/${hash}`);
	},
	getShareHashDataResume(jobId, hash) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/job/${jobId}/resume/share/${hash}`);
	},
	getShareHashDataTrackingTotal(hash) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/job/status/tracking/total/share/${hash}`);
	},
};
export default api;
