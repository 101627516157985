import React, { useEffect, useState, useMemo } from 'react';
import { Box, Paper, Typography, Chip, alpha } from '@mui/material';
import { TagCloud } from 'react-tagcloud';
import jobAPi from 'src/api/job.api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as R from 'ramda';

dayjs.extend(utc);

const stopwords = [
	'i',
	'ii',
	'iii',
	'me',
	'my',
	'myself',
	'we',
	'our',
	'ours',
	'ourselves',
	'you',
	'your',
	'yours',
	'yourself',
	'yourselves',
	'he',
	'him',
	'his',
	'himself',
	'she',
	'her',
	'hers',
	'herself',
	'it',
	'its',
	'itself',
	'they',
	'them',
	'their',
	'theirs',
	'themselves',
	'what',
	'which',
	'who',
	'whom',
	'this',
	'that',
	'these',
	'those',
	'am',
	'is',
	'are',
	'was',
	'were',
	'be',
	'been',
	'being',
	'have',
	'has',
	'had',
	'having',
	'do',
	'does',
	'did',
	'doing',
	'a',
	'an',
	'the',
	'and',
	'but',
	'if',
	'or',
	'because',
	'as',
	'until',
	'while',
	'of',
	'at',
	'by',
	'for',
	'with',
	'about',
	'against',
	'between',
	'into',
	'through',
	'during',
	'before',
	'after',
	'above',
	'below',
	'to',
	'from',
	'up',
	'down',
	'in',
	'out',
	'on',
	'off',
	'over',
	'under',
	'again',
	'further',
	'then',
	'once',
	'here',
	'there',
	'when',
	'where',
	'why',
	'how',
	'all',
	'any',
	'both',
	'each',
	'few',
	'more',
	'most',
	'other',
	'some',
	'such',
	'no',
	'nor',
	'not',
	'only',
	'own',
	'same',
	'so',
	'than',
	'too',
	'very',
	's',
	't',
	'can',
	'will',
	'just',
	'don',
	'should',
	'now',
];
const jobTitleChip = (tag, size, color) => {
	return (
		<Chip
			label={tag.value}
			key={tag.value}
			sx={{
				fontSize: `${size}px`,
				height: `${size + 10}px`,
				borderRadius: `${size + 5}px`,
				m: 0.3,
				backgroundColor: alpha(color, 0.7),
			}}
		/>
	);
};

const sigmoid = (x) => {
	return Math.exp(x) / (Math.exp(x) + 1);
};

export default function TitleTracking() {
	const [alignment, setAlignment] = useState(90);
	const [titleList, setTitleList] = useState([]);

	const handleAlignment = (event, newAlignment) => {
		if (newAlignment !== null) {
			setAlignment(newAlignment);
		}
	};

	const titleData = useMemo(() => {
		let wordList = [];
		titleList.forEach((item) => {
			for (let i = 0; i < item.cnt; i++) {
				wordList = wordList.concat(item.title.match(/[A-Za-z0-9]+/g));
			}
		});
		const wordCountObj = R.countBy(R.toLower)(wordList);
		const wordCountList = [];
		for (const [key, value] of Object.entries(wordCountObj)) {
			if (!stopwords.includes(key)) {
				wordCountList.push({
					value: key,
					count: sigmoid(value),
				});
			}
			if (wordCountList.length === 50) break;
		}
		return wordCountList;
	}, [titleList]);

	const colorOptions = {
		luminosity: 'light',
		hue: 'blue',
	};

	useEffect(() => {
		(async () => {
			const response = await jobAPi.getTitleTracking({
				startDate: dayjs().subtract(alignment, 'd').format('YYYY-MM-DD'),
				endDate: dayjs().format('YYYY-MM-DD'),
			});
			setTitleList(response.data);
		})();
	}, [alignment]);

	return (
		<Paper
			sx={{
				p: 2,
				minHeight: '300px',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box sx={{ flex: '0 0 auto' }}>
				<Typography variant="h6" gutterBottom>
					Saved Job Titles
				</Typography>
			</Box>
			{!!titleList?.length && (
				<TagCloud
					minSize={12}
					maxSize={36}
					tags={titleData}
					className="mt-2"
					colorOptions={colorOptions}
					renderer={jobTitleChip}
				/>
			)}
		</Paper>
	);
}
