import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMemo } from 'react';
import styles from './Resume.module.scss';
import { Divider } from '@mui/material';
import dayjs from 'dayjs';
import { getStartDate, getEndDate } from './utils';

const Item = ({ item = {}, isLast }) => {
	const startDate = useMemo(() => {
		return dayjs(`${item?.issueYear}-${item?.issueMonth}`).format('MMM YYYY');
	}, [item]);

	const expireDate = useMemo(() => {
		if (item?.noExpire) return 'No Expiration Date';
		return (
			'Expires ' +
			dayjs(`${item?.expireYear}-${item?.expireMonth}`).format('MMM YYYY')
		);
	}, [item]);

	return (
		<>
			<div className="hover:bg-slate-100 p-2">
				<div className={`${styles.company}`}>{item?.provider}</div>
				<div className={`${styles.date}`}>
					Issued {startDate} - {expireDate}
				</div>
			</div>
			{isLast ? <></> : <Divider />}
		</>
	);
};

export default function Certifications({ certifications = [] }) {
	return (
		<Accordion defaultExpanded>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="summary"
				id="summary"
			>
				<div
					style={{ fontSize: '1.2rem', fontWeight: '700', color: '#334155' }}
				>
					Licenses & Certifications
				</div>
			</AccordionSummary>
			<AccordionDetails>
				{certifications?.map((item, index) => (
					<Item
						item={item}
						key={index}
						isLast={certifications.length - 1 === index}
					/>
				))}
			</AccordionDetails>
		</Accordion>
	);
}
