import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMemo } from 'react';
import styles from './Resume.module.scss';
import { Divider } from '@mui/material';
import { trimHtml } from 'src/libs/utils';
import { getStartDate, getEndDate } from './utils';

const Item = ({ item = {}, isLast }) => {
	const startDate = useMemo(() => {
		return getStartDate(item?.startYear, item?.startMonth);
	}, [item?.startYear, item?.startMonth]);

	const endDate = useMemo(() => {
		return getEndDate(item?.isCurrent, item?.endYear, item?.endMonth);
	}, [item?.isCurrent, item?.endYear, item?.endMonth]);

	return (
		<>
			<div className="hover:bg-slate-100 p-2">
				<div className={`${styles.position}`}>{item?.projectName}</div>
				<div className={`${styles.date}`}>
					{startDate} - {endDate}
				</div>
				<div className="basis-4"></div>
				<div className={`${styles.description}`}>
					{trimHtml(item?.description)}
				</div>
			</div>
			{isLast ? <></> : <Divider />}
		</>
	);
};

export default function Projects({ projects = [] }) {
	return (
		<Accordion defaultExpanded>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="summary"
				id="summary"
			>
				<div
					style={{ fontSize: '1.2rem', fontWeight: '700', color: '#334155' }}
				>
					Projects
				</div>
			</AccordionSummary>
			<AccordionDetails>
				{projects?.map((item, index) => (
					<Item
						item={item}
						key={index}
						isLast={projects.length - 1 === index}
					/>
				))}
			</AccordionDetails>
		</Accordion>
	);
}
