import React, { useEffect, useState } from 'react';
import Top from './components/Top';
import About from './components/About';
import Prompt from './components/Prompt';
import Skill from './components/Skill';
import StatusTracking from './components/StatusTracking';
import TitleTracking from './components/TitleTracking';
import resumeApi from 'src/api/resume.api';
import useBackdrop from 'src/components/Backdrop/useBackdrop';
import { Button, Grid, Box, Divider } from '@mui/material';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import UploadDialog from '../ResumeBuilder/components/UploadDialog';
import MyLanguage from './components/MyLanguage';
import Certification from './components/Certification';
import IdealJob from './components/IdealJob';
import userApi from 'src/api/user.api';
import useToast from 'src/components/Toast/useToast';

// import Dialog from '@mui/material/Dialog';

export default function Dashboard({ isPublic }) {
	const [ready, setReady] = useState(false);
	// const [showStatusChart, setShowStatusChart] = useState(false)
	const [primaryResume, setPrimaryResume] = useState({});
	const [user, setUser] = useState({});
	const { showSuccess, showError } = useToast();

	const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop();

	const [openUploadDialog, setOpenUploadDialog] = useState(false);
	const [resumeUploaded, setResumeUploaded] = useLocalStorage(
		'resumeUploaded',
		0
	);

	const getUser = async () => {
		const response = await userApi.getCurrentWebUser();
		setUser(response.data);
	};
	const handleUpdateResume = (newResume) => {
		try {
			setPrimaryResume(newResume);
			resumeApi.updateProfile(newResume.profile.id, newResume.profile);
			resumeApi.updateSummary(newResume.summary.id, newResume.summary);
			newResume.educations[0]
				? resumeApi.updateEducation(
						newResume.educations[0].id,
						newResume.educations[0]
					)
				: resumeApi.createEducation(newResume.educations[0]);
			for (const experience of newResume.experiences)
				resumeApi.updateExperience(experience.id, experience);
			showSuccess('My prime resume updated!');
		} catch (e) {
			showError(e);
		}
	};

	const getPrimaryResume = async () => {
		try {
			const listResponse = await resumeApi.listResume({
				query: 'isPrimary:true',
			});
			if (listResponse?.data?.rows?.[0]) {
				const resumeResponse = await resumeApi.getResume(
					listResponse.data.rows[0].id
				);
				setPrimaryResume(resumeResponse.data);
			}
		} catch (err) {
			console.error('error occurred while getting priomary resume : ', err);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				showBackdrop();
				await getPrimaryResume();
			} catch (err) {
				console.error(err);
			} finally {
				setReady(true);
				closeBackdrop();
			}
		})();
		getUser();
	}, []);
	return (
		<>
			{ready && (
				<Box style={{ display: 'flex', justifyContent: 'center' }}>
					<Grid container spacing={2} maxWidth={'laptop'}>
						<Grid item xs={12}>
							<Top setOpenUploadDialog={setOpenUploadDialog} />
						</Grid>
						<Grid item xs={12} sm={8} md={8}>
							<About
								primaryResume={primaryResume}
								updateResume={handleUpdateResume}
								setOpenUploadDialog={setOpenUploadDialog}
							/>
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<IdealJob
								jobLocations={user?.location || []}
								jobType={user?.jobType || []}
								jobLevel={user?.jobLevel || []}
								workType={user?.workType || []}
								userId={user?.id}
							/>
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<MyLanguage langs={user?.languages || []} userId={user?.id} />
						</Grid>
						<Grid item xs={12} sm={4} md={4}>
							<Certification
								certifications={primaryResume.certifications}
								primaryResume={primaryResume}
								getPrimaryResume={getPrimaryResume}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Skill
								skills={primaryResume.skills}
								primaryResume={primaryResume}
								getPrimaryResume={getPrimaryResume}
							/>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Divider sx={{ marginBottom: 2 }} />
							<Prompt />
							<Divider sx={{ marginTop: 2 }} />
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<StatusTracking />
						</Grid>
						<Grid item xs={12} sm={6}>
							<TitleTracking />
						</Grid>
					</Grid>
					{openUploadDialog && (
						<UploadDialog
							showBackdrop={showBackdrop}
							closeBackdrop={closeBackdrop}
							setOpenUploadDialog={setOpenUploadDialog}
							openUploadDialog={openUploadDialog}
							resumeUploaded={resumeUploaded}
							setResumeUploaded={setResumeUploaded}
							getPrimaryResume={getPrimaryResume}
						></UploadDialog>
					)}
				</Box>
			)}
			<BackdropComponent />
		</>
	);
}
